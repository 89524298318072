import React, { useEffect } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import Page from '../components/layout/Page';
import TopBar from '../components/bcc/TopBar';
import { AnimatePresence } from 'framer-motion';
import AuthProvider from '../components/providers/AuthProvider';
import NProgress from 'nprogress';

import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import '../node_modules/nprogress/nprogress.css';

const MyApp = ({ Component, pageProps, router }) => {
  useEffect(() => {
    router.events.on('routeChangeStart', () => NProgress.start()); 
    router.events.on('routeChangeComplete', () => {
      window.scrollTo({
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
      NProgress.done(); 
    });
    router.events.on('routeChangeError', () => NProgress.done());
  }, []);

  return (
    <AuthProvider router={router}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <Page {...pageProps} key={router.route}>
          <AnimatePresence exitBeforeEnter>
            <Component {...pageProps} key={router.route} />
          </AnimatePresence>
        </Page>
        <TopBar />
    </AuthProvider>
  );
}

MyApp.propTypes = {
  pageProps: PropTypes.object,
  Component: PropTypes.func
};

export default MyApp;
