import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { easing } from '../../lib/MotionHelper';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.margins.large};
  position: relative;
`

const Content = styled(motion.div)`
  width: 100%;
  box-sizing: border-box;
  flex: 1 1 auto;
  padding: 0 ${({ theme }) => theme.margins.small};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small.maxWidth}) {
    min-width: ${({ theme }) => theme.breakpoints.small.contentWidth};
    max-width: ${({ theme }) => theme.breakpoints.small.contentWidth};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
    flex-direction: row;
    min-width: ${({ theme }) => theme.breakpoints.medium.contentWidth};
    max-width: ${({ theme }) => theme.breakpoints.medium.contentWidth};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large.maxWidth}) {
    min-width: ${({ theme }) => theme.breakpoints.large.contentWidth};
    max-width: ${({ theme }) => theme.breakpoints.large.contentWidth};
  }
`

const DefaultBody = ({ children }) => {
  return <Container>
      <Content initial="initial" animate="animate" exit="exit" variants={{ 
        initial: { opacity: 0, y: 10, transition: { duration: 0.5, ease: easing } },
        animate: { opacity: 1, y: 0, transition: { duration: 0.6, ease: easing } },
        exit: { opacity: 0 }
      }}>
      {children}
      </Content>
  </Container>
}

export default DefaultBody;