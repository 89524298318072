import React from 'react';
import { useRouter } from 'next/router'
import PropTypes from 'prop-types';
import Header from './Header';
import DefaultBody from './DefaultBody';
import FullBody from './FullBody';
import useAuth from '../hooks/useAuth';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Theme from '../common/Theme';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    background: ${props => props.backgroundColor};
    font-weight: 300;
    font-family: 'Archivo', sans-serif;
    font-size: ${props => props.fontSize};
    color: ${props => props.textColor};
    min-height: 100vh;
    width: 100%;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    letter-spacing: 0.25px;
  }

  #__next {
    max-width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  #nprogress {
    .bar, 
    .spinner {
      z-index: 10001;
    }
  }

  a {
    color: #004c45;
  }
`

const Page = ({ children, menu, siteInfo, preview }) => {
  const router = useRouter();
  const auth = useAuth();
  
  if (!auth.isAuthenticated) {
    return null;
  }

  var body = <DefaultBody>
    {children}
  </DefaultBody>;

  if (router.pathname == "/article/[slug]") {
    body = <FullBody>
      {children}
    </FullBody>;
  }

  return (
    <>
      <GlobalStyle backgroundColor={Theme.colors.contrastBackground} fontSize={Theme.fontSizes.medium} textColor={Theme.colors.text}/>
      <ThemeProvider theme={Theme}>
        <Header siteInfo={siteInfo} menu={menu}/>
        {body} 
      </ThemeProvider>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.object.isRequired
};

export default Page;
