import React, { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import createAuth0Client from '@auth0/auth0-spa-js';

export const AuthContext = createContext();

const AuthProvider = ({ children, router }) => {
  const [user, setUser] = useState({});
  const [auth0Client, setAuth0Client] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      var auth0 = await createAuth0Client({
        domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
        client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
        redirect_uri: process.env.NEXT_PUBLIC_REDIRECT_URI,
        audience: "https://widgets.brunstad.org",
        cacheLocation: 'localstorage'
      });

      setAuth0Client(auth0);

      if (window.location.search.includes('code=')) {
        try {
          const { appState } = await auth0.handleRedirectCallback();
  
          router.push(appState && appState.targetUrl ? appState.targetUrl : '/');
        }
        catch (e) {
          if (process.env.NEXT_PUBLIC_NODE_ENV !== "production") {
            throw e;
          }
          else {
            router.push('/');
          }
        }
      }

      const isAuthenticated = await auth0.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
    
      if (isAuthenticated) {
        var u = await auth0.getUser();
        setUser(u);

        var token = await auth0.getTokenSilently();
        localStorage.setItem('_bcc_widget', token)  
      }
      else {
        await auth0.loginWithRedirect({ targetUrl: router.asPath });
      }

      setIsLoading(false);
    }

    initAuth0();
  }, []);

  var ctx = {
    user: user,
    isAuthenticated: isAuthenticated,
    auth0Client: auth0Client,
  };

  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
