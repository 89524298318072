export const easing = [0.6, -0.05, 0.01, 0.99];
export const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing }
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing
    }
  }
};
export const fadeIn = {
  initial: {
    opacity: 0,
    transition: { duration: 2.6, ease: easing }
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 2.6,
      ease: easing
    }
  }
};

export const fadeInState = {
  loading: () => ({
    opacity: 0,
    transition: { duration: 0.6, ease: easing }
  }),
  loaded: () => ({
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing
    }
  })
};

export const fadeOutState = {
  loading: () => ({
    opacity: 1,
    transition: { duration: 0.6, ease: easing }
  }),
  loaded: () => ({
    opacity: 0,
    transition: { duration: 0.6, ease: easing }
  })
};