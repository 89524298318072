import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Menu from '../layout/Menu';
import SanityFrontendClient from '../../lib/SanityFrontendClient';

const Container = styled.header`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};
`

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex: 1 1 auto;
  padding: 0 ${({ theme }) => theme.margins.small};
  display: flex;
  justify-content: flex-start;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small.maxWidth}) {
    min-width: ${({ theme }) => theme.breakpoints.small.contentWidth};
    max-width: ${({ theme }) => theme.breakpoints.small.contentWidth};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
    flex-direction: row;
    min-width: ${({ theme }) => theme.breakpoints.medium.contentWidth};
    max-width: ${({ theme }) => theme.breakpoints.medium.contentWidth};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large.maxWidth}) {
    min-width: ${({ theme }) => theme.breakpoints.large.contentWidth};
    max-width: ${({ theme }) => theme.breakpoints.large.contentWidth};
  }
`

const Logo = styled.img`
  padding: 15px 0;
  height: 50px;
`

const Header = ({ siteInfo, menu }) => {
  const sanityFrontendClient = new SanityFrontendClient();

  return <Container>
    <Content>
      <Link href="/">
        <a>
          {siteInfo && <Logo src={sanityFrontendClient.getImageUrl(siteInfo.logo).height(50).url()} alt={siteInfo.logo.alt}/>}
        </a>
      </Link>
      <Menu items={menu?.items || []}></Menu>
    </Content>
  </Container>
}

export default Header;