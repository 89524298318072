import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { motion, useCycle, AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import UrlHelper from '../../lib/UrlHelper';

const Container = styled.nav`
  width: 100%;
  margin-left: 40px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large.maxWidth}) {
    margin-left: 80px;
  }
`

const MenuItem = styled.div`
  text-transform: uppercase;

  &:first-child {
    margin-left: 0;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`

const FullMenuItem = styled(MenuItem)`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.margins.small};
  border-bottom: 5px solid transparent;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large.maxWidth}) {
    margin-left: ${({ theme }) => theme.margins.medium};
  }

  &.active {
    border-bottom: 5px solid ${({ theme }) => theme.colors.primary};
  }

  a {
    padding: ${({ theme }) => theme.margins.medium};
  }
`

const HamburgerMenuItem = styled(MenuItem)`
  margin-top: ${({ theme }) => theme.margins.medium};

  &:first-child {
    margin-top: 0;
  }

  &.active {
    font-weight: 600;
  }
`

const FullMenu = styled.div`
  width: 100%;
  height: 100%;
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
    display: flex;
    align-items: center;
  }
`

const HamburgerMenu = styled.div`
  width: 100%;
  position: relative;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium.maxWidth}) {
    display: none;
  }

  .hamburger-menu-wrapper {
    position: absolute;
    top: 16px;
    right: 0;
    z-index: 9998;
    border-radius: 24px;
    background: white;
    padding: 48px;
  }
`

const Hamburger = styled.button`
  border: none;
  background: transparent;
  border-radius: 24px;
  overflow: hidden;
  width: 48px;
  height: 48px;
  padding: 0;
  position: absolute;
  top: 16px;
  right: 0;
  z-index: 9999;

  &:focus {
    outline: 0;
  }
`

const MotionPath = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#2F4773"
    strokeLinecap="round"
    {...props}
  />
);

const MenuAnimation = {
  open: () => ({
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
    clipPath: "circle(500px at calc(100% - 24px) 24px)",
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 100,
      damping: 40,
      restDelta: 2
    }
  }),
  closed: () => ({
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.3)",
    clipPath: "circle(32px at calc(100% - 24px) 24px)",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  })
}

const Menu = ({ items }) => {
  const router = useRouter();
  const containerRef = useRef(null);
  const [isOpen, toggleOpen] = useCycle(false, true);

  return <Container>
      <FullMenu>
        {items.map((item, i) => {
          return <FullMenuItem key={i} className={router.pathname == item.url ? "active" : ""}>
            {UrlHelper.isExternal(item.url) ?
            <a href={item.url}>{item.text}</a> :
            <Link href={item.url}>
              <a>{item.text}</a>
            </Link>}
          </FullMenuItem>
        })}
      </FullMenu>
      <HamburgerMenu>
        <motion.nav
          initial={false}
          animate={isOpen ? "open" : "closed"}
        >
          <motion.div 
            className="hamburger-menu-wrapper"
            variants={MenuAnimation}
          >
            {items.map((item, i) => {
              return <HamburgerMenuItem key={i} className={router.pathname == item.url ? "active" : ""}>
                {UrlHelper.isExternal(item.url) ?
                <a href={item.url}>{item.text}</a> :
                <Link href={item.url}>
                  <a>{item.text}</a>
                </Link>}
              </HamburgerMenuItem>
            })}
          </motion.div>
          <Hamburger onClick={() => toggleOpen()}>
            <svg width="24" height="24" viewBox="-1.5 -2.5 24 24">
              <MotionPath
                variants={{
                  closed: { d: "M 2 2.5 L 20 2.5" },
                  open: { d: "M 3 16.5 L 17 2.5" }
                }}
              />
              <MotionPath
                d="M 2 9.423 L 20 9.423"
                variants={{
                  closed: { opacity: 1 },
                  open: { opacity: 0 }
                }}
                transition={{ duration: 0.1 }}
              />
              <MotionPath
                variants={{
                  closed: { d: "M 2 16.346 L 20 16.346" },
                  open: { d: "M 3 2.5 L 17 16.346" }
                }}
              />
            </svg>
          </Hamburger>
        </motion.nav>
      </HamburgerMenu>
  </Container>
}

export default Menu;