const Theme = {
    radius: '20px',
    margins: {
      extraSmall: '5px',
      small: '10px',
      medium: '20px',
      large: '30px',
      huge: '50px',
    },
    colors: {
      background: 'white',
      contrastBackground: '#EFEFEF',
      border: '#EEEEEE',
      subtitle: '#707070',
      primary: '#2F4773',
      text: '#000000',
      disabled: '#6c757d'
    },
    breakpoints: {
      extraSmall: {
        maxWidth: '576px',
        contentWidth: '540px',
        margin: '16px',
      },
      small: {
        maxWidth: '768px',
        contentWidth: '720px',
        margin: '24px',
      },
      medium: {
        maxWidth: '992px',
        contentWidth: '960px',
        margin: '32px',
      },
      large: {
        maxWidth: '1200px',
        contentWidth: '1140px',
        margin: '32px',
      }
    },
    fontSizes: {
      microscopic: '10px',
      extraSmall: '12px',
      small: '14px',
      normal: '16px',
      large: '20px',
      extraLarge: '24px',
      huge: '32px',
      enormous: '42px',
    }
}

export default Theme;