import React, { useState, useEffect } from 'react'
import Head from 'next/head';

const TopBar = ({ preview }) => {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const existingScript = document.getElementById('script-bcc-topbar');
        var token = localStorage.getItem('_bcc_widget');

        if (token && !existingScript) {
            const script = document.createElement('script');
            script.src = 'https://widgets.bcc.no/widgets/TopbarJs';
            script.id = 'script-bcc-topbar';
            script.setAttribute("data-authentication-type", "SPA");
            script.setAttribute("data-authentication-location", "_bcc_widget");
            document.body.appendChild(script);

            setIsInitialized(true);
        }
    }, []);

    if (isInitialized) {
        return <>
            <Head>
                <link href="https://widgets.bcc.no/styles/widgets.css" rel="stylesheet" type="text/css"></link>
            </Head>
        </>
    }

    return null;
}

export default TopBar;