import sanityClient from '@sanity/client';
import sanityImage from '@sanity/image-url';

class SanityClient {
    constructor() {        
        const options = {
            projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
            dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
            apiVersion: '2021-08-31',
            useCdn: process.env.NODE_ENV === 'production'
        };
        this.client = sanityClient(options);
        this.imageBuilder = sanityImage(this.client);
        this.previewClient = sanityClient({
            ...options,
            useCdn: false
        });
    }

    #getClient(preview) {
        return preview ? this.previewClient : this.client;
    }

    getImageUrl(source) {
        return this.imageBuilder.image(source);
    }
}

export default SanityClient;